
import { Component, Vue } from 'vue-property-decorator';
import Button from '@/components/Buttons/Button.vue';
import { addHours } from 'date-fns';

@Component({
  components: {Button},
})
export default class PushNotifications extends Vue {
  step: number = 1;
  isVisible: boolean = false;

  mounted() {
    const storageValue = localStorage.getItem('pushNotifications');
    if (
        !storageValue ||
        storageValue === 'passed' ||
        Date.now() >= Date.parse(storageValue)
    ) {
      this.isVisible = true;
    }
  }

  closeModal(isLater: boolean = true) {
    localStorage.setItem(
        'pushNotifications',
        isLater ? addHours(new Date(), 12).toString() : 'pass'
    );
    this.isVisible = false;
  }

  subNotifications() {
    // @ts-ignore
    document.getElementsByClassName('sp_notify_prompt')[0].click();
    this.step++;
  }
}
