

import { Component, Prop, Vue } from 'vue-property-decorator';
import TextHeader from '@/components/Layout/TextHeader.vue';

@Component({ components: { TextHeader } })
export default class Checkbox extends Vue {
  @Prop(Boolean) checkedOnStart: boolean;
  checked: boolean = false;
  
  mounted() {
    if (this.checkedOnStart) this.checked = true;
  }

  toggle() {
    this.checked = !this.checked;
    this.$emit('onChange', this.checked);
  }
}

