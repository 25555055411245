
import { Component, Vue } from 'vue-property-decorator';
import TextHeader from '@/components/Layout/TextHeader.vue';
import Button from '@/components/Buttons/Button.vue';
import PadButton from '@/components/Buttons/PadButton.vue';

@Component({ components: { TextHeader, Button, PadButton } })
export default class ManaModal extends Vue {
  manaCaseIds = [45, 37, 38, 40, 41];

  goToCase(n: number) {
    this.$router.push('/case/' + this.manaCaseIds[n - 1]);
    this.modalClose();
  }

  modalClose() {
    this.$emit('close');
  }
}
