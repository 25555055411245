
import { Component, Vue } from 'vue-property-decorator';
import DropsFeed from './components/Layout/DropsFeed.vue';
import LoadingScreen from './components/Layout/LoadingScreen.vue';
import Footer from './components/Layout/Footer.vue';
import Header from './components/Layout/Header.vue';
import Modals from './components/Modals/Modals.vue';
import PushNotifications from '@/components/Layout/PushNotifications.vue';

@Component({
  components: {
    PushNotifications,
    Header,
    Footer,
    DropsFeed,
    LoadingScreen,
    Modals
  }
})
export default class Layout extends Vue {
  public loading: boolean = true;
  isPositionOnTop: boolean = false;

  created() {
    window.addEventListener('scroll', this.handleScroll);
  }

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    this.isPositionOnTop = window.scrollY > 80;
  }

  async mounted() {
    try {
      this.$store.dispatch('user/fetch');
      this.loaded();
    } catch (e) {
      console.log(e);
      this.loaded();
    }
  }


  get user() {
    return this.$store.getters['user/isAuthorized'] ? this.$store.state.user : null;
  }

  public loaded() {
    setTimeout(() => {
      this.loading = false;
    }, 400);
  }
}
