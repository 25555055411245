
import { Component, Vue } from 'vue-property-decorator';
import TextHeader from '@/components/Layout/TextHeader.vue';
import Button from '@/components/Buttons/Button.vue';
import Item from '@/components/Item/Item.vue';
import { Item as IItem } from '@/types/Item';
import withdrawSimilarItem from '@/requests/Withdraw/withdrawSimilarItem/withdrawSimilarItem';
import sellItem from '@/requests/Withdraw/sellItem/sellItem';
import { getDropSellPrice } from '@/helpers/getDropSellPrice';
import { UserDrop } from '@/types/User';
import DropInventory from '@/components/DropInventory/DropInventory.vue';

@Component({ components: { TextHeader, Button, Item } })
export default class SimilarWithdrawModal extends Vue {
  withdrawItem: IItem | null = null;
  blockRequest: boolean = false;

  get drop(): UserDrop {
    return this.$store.state.modal.data
      ? this.$store.state.modal.data.dropItem
      : null;
  }

  get replaceItems(): IItem[] {
    return this.$store.state.modal.data
      ? this.$store.state.modal.data.replaceItems
      : null;
  }

  get inventoryRef(): DropInventory {
    return this.$store.state.modal.data
      ? this.$store.state.modal.data.ref
      : null;
  }

  get sellPrice() {
    return getDropSellPrice(this.drop.item.price);
  }

  async sell() {
    if (this.blockRequest || !this.drop) return;
    this.blockRequest = true;

    try {
      const { user } = await sellItem(this.drop.id);
      this.$store.commit('user/update', user);

      this.$notify({
        group: 'notification',
        duration: 5500,
        type: 'success',
        title: 'Инвентарь',
        text: 'Предмет успешно продан!',
      });
      this.inventoryRef.removeDrop(this.drop.id);

      this.$emit('close');
    } catch (e) {
      if (e instanceof String) {
        return this.$notify({
          group: 'notification',
          duration: 5500,
          type: 'warning',
          title: 'Инвентарь',
          text: e as string,
        });
      }
      console.log(e);
      this.$notify({
        group: 'notification',
        duration: 5500,
        type: 'error',
        title: 'Инвентарь',
        text: 'Неизвестная ошибка!',
      });
    } finally {
      this.blockRequest = false;
    }
  }

  async withdraw() {
    if (this.blockRequest || !this.withdrawItem || !this.drop.id) return;
    this.blockRequest = true;

    try {
      this.$notify({
        group: 'notification',
        duration: 5500,
        type: 'success',
        title: 'Инвентарь',
        text: 'Пробуем вывести ваш предмет!',
      });

      const { error, replaceItems: _replaceItems } = await withdrawSimilarItem(
        this.drop.id,
        this.withdrawItem.market_hash_name
      );

      if (error) {
        return this.$notify({
          group: 'notification',
          duration: 5500,
          type: 'warning',
          title: 'Инвентарь',
          text: error,
        });
      }
      if (_replaceItems) {
        this.$store.dispatch('modal/toggleModal', {
          name: 'replaceItem',
          data: {
            replaceItems: _replaceItems,
            dropItem: this.drop,
            ref: this.inventoryRef,
          },
        });
      }
      this.$notify({
        group: 'notification',
        duration: 5500,
        type: 'success',
        title: 'Инвентарь',
        text: 'Совсем скоро Вы получите свой предмет! Проверяйте свои обмены почаще! Если Вы не примите обмен, то помочь мы не сможем!',
      });
      this.inventoryRef.proceedDropToPendingWithdraw(this.drop.id, 'item');
      this.$emit('close');
    } catch (e) {
      console.error(e);
      this.$notify({
        group: 'notification',
        duration: 5500,
        type: 'warning',
        title: 'Инвентарь',
        text: 'Попробуйте позже!',
      });
    } finally {
      this.blockRequest = false;
    }
  }
}
