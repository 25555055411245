
import { Component, Vue } from 'vue-property-decorator';

import getDrops from '@/requests/Drops/getDrops';
import { Drop } from '@/types/Drop';
import ItemImage from '@/components/Item/ItemImage.vue';
import CaseImage from '@/components/Case/CaseImage.vue';
import { getItemColor } from '@/helpers/getItemColor';
import { getItemDropSourceLink } from '@/helpers/getItemDropSourceLink';
import ItemSource from '@/components/Item/ItemSource.vue';
import Item from '@/components/Item/Item.vue';

@Component({
  components: { Item, ItemSource, CaseImage, ItemImage },
})
export default class DropsFeed extends Vue {
  type: 'total' | 'top' | 'gift' = 'total';
  allDrops: {
    total: Drop[];
    top: Drop[];
    gift: Drop[];
  } = {
    total: [],
    top: [],
    gift: [],
  };
  topDrop: Drop;

  mounted() {
    this.$socket.client.on('new_drop', (drop: Drop) => {
      this.allDrops.total.unshift(drop);
      if (this.allDrops.total.length > 21) {
        this.allDrops.total.splice(20, this.allDrops.total.length - 1);
      }
    });

    this.getDrops();
  }

  get dropsToDisplay() {
    return [this.topDrop, ...this.allDrops[this.type]];
  }

  changeType(type) {
    this.type = type;
    if (type !== 'total') {
      this.getDrops();
    }
  }

  async getDrops() {
    try {
      this.allDrops[this.type] = await getDrops({ type: this.type });

      if (this.type === 'total') {
        this.topDrop = this.allDrops['total'][0];
        this.allDrops['total'].shift();
      }
    } catch (e) {
      console.error(e);
    }
  }

  getSourceLink(drop: Drop) {
    return getItemDropSourceLink(drop);
  }

  getColor(color: string) {
    return getItemColor(color);
  }

  toRGB(color: string) {
    const aRgbHex = color.replace('#', '').match(/.{1,2}/g);
    const aRgb = [
      parseInt(aRgbHex[0], 16),
      parseInt(aRgbHex[1], 16),
      parseInt(aRgbHex[2], 16),
    ];
    return aRgb.join(',');
  }
}
