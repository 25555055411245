var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"live-bar"},[_c('div',{staticClass:"live-bar-menu"},[_c('div',{staticClass:"live-bar-menu__item",class:{ '--active': _vm.type === 'total' },on:{"click":function($event){return _vm.changeType('total')}}},[_c('div',[_c('InlineSvg',{attrs:{"src":require('@/assets/img/icons/live-bar-live.svg')}}),_c('span',{staticClass:"live-bar-menu__item__label"},[_vm._v("Live")])],1)]),_c('div',{staticClass:"live-bar-menu__item live-bar-menu__item--top",class:{ '--active': _vm.type === 'top' },on:{"click":function($event){return _vm.changeType('top')}}},[_c('div',[_c('InlineSvg',{attrs:{"src":require('@/assets/img/icons/live-bar-top.svg')}}),_c('span',{staticClass:"live-bar-menu__item__label"},[_vm._v("Top")])],1)]),_c('div',{staticClass:"live-bar-menu__item live-bar-menu__item--gift",class:{ '--active': _vm.type === 'gift' },on:{"click":function($event){return _vm.changeType('gift')}}},[_c('div',[_c('InlineSvg',{attrs:{"src":require('@/assets/img/icons/live-bar-gift.svg')}}),_c('span',{staticClass:"live-bar-menu__item__label"},[_vm._v("Gift")])],1)])]),_c('div',{staticClass:"drop-items-wrap"},[_c('div',{staticClass:"drop-items"},_vm._l((_vm.dropsToDisplay),function(drop,index){return (drop)?_c('div',{key:drop.id.toString(),class:{
          'drop-item': true,
          'drop-item--light': index === 0,
          'drop-item--mana': drop.case && drop.case.isManaCase,
        }},[_c('div',{staticClass:"drop-item__line",style:({
            'background-color': '#' + _vm.getColor(drop.item.color),
          })}),_c('div',{staticClass:"drop-item__shadow",style:({
            'box-shadow':
              'inset 0px -10px 15px 1px rgba(' +
              _vm.toRGB(_vm.getColor(drop.item.color)) +
              ', 0.3)',
          })}),_c('Item',{attrs:{"itemData":drop.item,"priceMode":"hidden","hoverMode":"normal","hideTitle":""},scopedSlots:_vm._u([{key:"topRight",fn:function(){return [_c('ItemSource',{attrs:{"drop":drop,"hideIcon":""}},[_c('router-link',{attrs:{"to":'/profile/' + drop.user.uuid}},[_c('div',{staticClass:"_user"},[_c('span',{staticClass:"_avatar"},[_c('img',{attrs:{"src":drop.user.avatar}})]),_c('span',{staticClass:"_name"},[_vm._v(_vm._s(drop.user.name))])])])],1)]},proxy:true}],null,true)})],1):_vm._e()}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }