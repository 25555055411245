var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('header',{class:['header', 'sticky-top', _vm.isLogoHovered ? '--logo-hover' : '']},[_c('div',{staticClass:"header-left-part"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("../../assets/img/logo-header.svg"),"width":"91","alt":"Dota Loot"},on:{"mouseover":_vm.logoHover,"mouseout":_vm.logoHover}})]),_c('div',{staticClass:"header-user-stat"},[_c('img',{staticClass:"header-user-stat__icon",attrs:{"src":require("../../assets/img/icon-users-stat.svg")}}),_c('div',[_c('div',{staticClass:"header-user-stat__qty"},[_vm._v(_vm._s(_vm.usersOnline))]),_c('div',{staticClass:"header-user-stat__lbl"},[_vm._v("онлайн")])])]),_c('nav',{staticClass:"header-menu"},[_c('ul',{staticClass:"no-style"},[_c('li',{staticClass:"header-menu__contract"},[_c('router-link',{class:[
              'header-menu__item',
              _vm.isPageActive('contract') ? '--active' : '',
            ],attrs:{"to":"/contract"}},[_c('span',[_c('InlineSvg',{attrs:{"src":require('@/assets/img/icons/contracts.svg')}})],1),_c('span',[_vm._v("Контракты")])])],1),_c('li',{staticClass:"header-menu__promo"},[_c('router-link',{class:[
              'header-menu__item',
              'header-menu__item--promo',
              _vm.isPageActive('promotions') ? '--active' : '',
            ],attrs:{"to":"/promotions"}},[_c('span',[_c('InlineSvg',{attrs:{"src":require('@/assets/img/icons/promotions.svg')}})],1),_c('span',[_vm._v("Лавка")]),_c('span',{staticClass:"_counter"},[_vm._v("9")])])],1),_c('li',{staticClass:"header-menu__upg"},[_c('router-link',{class:[
              'header-menu__item',
              'header-menu__item--upgrade',
              _vm.isPageActive('upgrade') ? '--active' : '',
            ],attrs:{"to":"/upgrade"}},[_c('span',[_c('InlineSvg',{attrs:{"src":require('@/assets/img/icons/upgrades.svg')}})],1),_c('span',[_vm._v("Апгрейды")]),_c('span',{staticClass:"_counter"},[_vm._v("new")]),_c('span',{staticClass:"_label"},[_c('span',[_vm._v("🔥")]),_vm._v("НОВИНКА")])])],1),_c('li',{staticClass:"header-menu__event"},[_c('router-link',{staticClass:"header-menu__item header-menu__item--wa",attrs:{"to":"/event"}},[_c('span',[_c('img',{staticClass:"_img",attrs:{"src":require('@/assets/img/wa-rune.png'),"alt":""}}),_c('InlineSvg',{staticClass:"_img--mob",attrs:{"src":require('@/assets/img/icons/blue-fire.svg')}})],1),_c('span',[_vm._v("Приключение")])])],1)]),_c('div',{staticClass:"header-menu__item header-menu__item--mob header-menu__item--menu-activate"},[_c('button',{class:[
            'hamburger',
            'hamburger--squeeze',
            _vm.isUserMenuShown || _vm.isMobileUserMenuShown ? 'is-active' : '',
          ],attrs:{"type":"button"},on:{"click":_vm.toggleMobileUserMenu}},[_vm._m(0)])])])],1),(!_vm.user)?_c('div',{staticClass:"header-user-area header-user-area--unlogged-in"},[_c('div',{staticClass:"_social"},[_c('a',{staticClass:"social-link",attrs:{"href":_vm.socialLink('vk'),"target":"_blank"}},[_c('Button',{attrs:{"type":"gray","icon":"vkontakte"}})],1),_c('a',{staticClass:"social-link",attrs:{"href":_vm.socialLink('tg'),"target":"_blank"}},[_c('Button',{attrs:{"type":"gray","icon":"telegram"}})],1)]),_c('div',{staticClass:"user-authorization-wrap"},[_c('div',{staticClass:"user-authorization"},[_c('div',{staticClass:"_lbl"},[_vm._v("Вход:")]),_c('div',{},[_c('AuthButton',{attrs:{"provider":"vkontakte"}})],1),_c('div',{},[_c('AuthButton',{attrs:{"provider":"steam"}})],1)])]),_c('div',{class:[
        'user-menu',
        _vm.isUserMenuShown || _vm.isMobileUserMenuShown ? '--active' : '',
      ]},[_c('div',{staticClass:"user-authorization-wrap"},[_c('div',{staticClass:"user-authorization"},[_c('div',{staticClass:"_lbl"},[_vm._v("Вход:")]),_c('div',{},[_c('AuthButton',{attrs:{"provider":"vkontakte"}})],1),_c('div',{},[_c('AuthButton',{attrs:{"provider":"steam"}})],1)])])])]):_c('div',{staticClass:"header-user-area header-user-area--logged-in"},[_c('div',{staticClass:"_social"},[_c('a',{staticClass:"social-link",attrs:{"href":_vm.socialLink('vk'),"target":"_blank"}},[_c('Button',{attrs:{"type":"gray","icon":"vkontakte"}})],1),_c('a',{staticClass:"social-link",attrs:{"href":_vm.socialLink('tg'),"target":"_blank"}},[_c('Button',{attrs:{"type":"gray","icon":"telegram"}})],1)]),_c('Button',{attrs:{"type":"refill","text":"Пополнить","icon":"plus"},nativeOn:{"click":function($event){return _vm.openRefill.apply(null, arguments)}}}),_c('div',{staticClass:"header-user-area__params"},[_c('div',{staticClass:"user-balance"},[_c('div',{staticClass:"_rub"},[_vm._m(1),_vm._v(" "+_vm._s(_vm.user.balance)+" ")]),_c('div',{staticClass:"_mana",on:{"click":_vm.openMana}},[_c('InlineSvg',{attrs:{"src":require('@/assets/img/icons/mana.svg')}}),_vm._v(" "+_vm._s(_vm.user.mana)+" ")],1)]),_c('div',{staticClass:"user-avatar"},[_c('img',{staticClass:"user-avatar__photo",attrs:{"src":_vm.user.avatar,"alt":"Профиль"},on:{"click":_vm.toUserProfile}}),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideUserMenu),expression:"hideUserMenu"}],staticClass:"user-avatar__menu-action",on:{"click":_vm.toggleUserMenu}},[_c('InlineSvg',{attrs:{"src":require('@/assets/img/icons/dropdown-arrows.svg')}})],1)])]),_c('div',{class:[
        'user-menu',
        _vm.isUserMenuShown || _vm.isMobileUserMenuShown ? '--active' : '',
      ]},[_c('ul',{staticClass:"no-style"},[_c('li',{staticClass:"_refill",on:{"click":_vm.openRefillInDropDown}},[_c('InlineSvg',{attrs:{"src":require('@/assets/img/icons/plus.svg')}}),_vm._v(" Пополнить ")],1),_c('li',{staticClass:"--mob-show _shop",on:{"click":_vm.lavkaFromUserMenu}},[_c('span',[_c('InlineSvg',{attrs:{"src":require('@/assets/img/icons/case-2.svg')}})],1),_c('span',[_vm._v("Кейсы")])]),_c('li',[_c('router-link',{attrs:{"to":"/account"}},[_c('InlineSvg',{attrs:{"src":require('@/assets/img/icons/profile.svg')}}),_vm._v(" Профиль ")],1)],1),_c('li',{staticClass:"_mob"}),_c('li',{staticClass:"_logout",on:{"click":_vm.logout}},[_c('InlineSvg',{attrs:{"src":require('@/assets/img/icons/logout.svg')}}),_vm._v(" Выход ")],1)]),_c('div',{staticClass:"user-menu__mob-social"},[_c('a',{staticClass:"social-link",attrs:{"href":_vm.socialLink('vk'),"target":"_blank"}},[_c('Button',{attrs:{"type":"gray","icon":"vkontakte"}})],1),_c('a',{staticClass:"social-link",attrs:{"href":_vm.socialLink('tg'),"target":"_blank"}},[_c('Button',{attrs:{"type":"gray","icon":"telegram"}})],1)])])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{staticClass:"hamburger-box"},[_c('span',{staticClass:"hamburger-inner"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{staticClass:"_r"},[_c('span',{staticClass:"coin --menu --small"})])
}]

export { render, staticRenderFns }