
import { Component, Vue, Watch } from 'vue-property-decorator';
import TextHeader from '@/components/Layout/TextHeader.vue';
import Button from '@/components/Buttons/Button.vue';
import Switcher from '@/components/Switcher.vue';
import Checkbox from '@/components/Checkbox.vue';
import { Promocode } from '@/types/Promocode';
import activatePromocode from '@/requests/Payment/activatePromocode/activatePromocode';
import createPayment from '@/requests/Payment/createPayment/createPayment';
import {
  PaymentMethod,
  PaymentFilterKeys,
  Country,
} from '@/types/PaymentCountry';
// @ts-ignore
import Multiselect from 'vue-multiselect';
import { ENABLED_COUNTRIES } from '@/constants/enabledCountries';

const DEFAULT_AMOUNT = {
  'RUB': 750,
  'BYN': 40,
  'KZT': 3500,
  'UAH': 350,
  'USD': 15,
  'EUR': 15,
};


@Component({
  components: {TextHeader, Button, Switcher, Checkbox, Multiselect},
})
export default class RefillModal extends Vue {
  amount: number = DEFAULT_AMOUNT['RUB'];
  inputPromocode: string | null = null;
  promocode: Promocode | null = null;
  isPromoCodeValid: boolean = false;
  isPromocodeInputVisible: boolean = false;
  chosenPaymentMethodIndex: number = -1;
  paymentFilter: PaymentFilterKeys = 'all';
  conditionsConfirmed: boolean = true;
  isCountryLabelVisible: Boolean = true;
  payInputWidth: Number = 30;

  mounted() {
    this.$store.dispatch('refill/fetch');
    this.chosenPaymentMethodIndex = 0;
    this.setDefaultAmount();
  }

  get countries(): Country[] {
    return ENABLED_COUNTRIES;
  }

  setDefaultAmount() {
    let amount = DEFAULT_AMOUNT[this.displayCurrency];
    if (this.promoData) {
      amount = this.coinsToDisplayCurrency(this.promoData.amount, true);
    }

    this.amount = Math.max(amount, this.minPaymentAmount);
  }

  get paymentMethods(): PaymentMethod[] {
    return this.$store.state.refill.methods;
  }

  get rates() {
    return {...this.$store.state.refill.rates, 'RUB': 1};
  }

  get user() {
    return this.$store.getters['user/isAuthorized']
        ? this.$store.state.user
        : null;
  }

  get selectedCountry(): Country {
    const country = this.countries.find(_ => this.user.country === _.code);

    return country ?? this.countries[this.countries.length - 1];
  }

  choosePaymentMethod(index: number) {
    if (this.filteredPaymentMethods[index].disabled || this.chosenPaymentMethodIndex === index) return;

    this.chosenPaymentMethodIndex = index;
  }

  setCountry(country: Country) {
    window.localStorage.setItem('selectedCountry', country.code);
    this.$store.commit('user/update', {country: country.code});
  }

  refillCountriesLabelToggle(action: string) {
    if (action == 'hide') {
      this.isCountryLabelVisible = false;
    } else {
      setTimeout(() => {
        this.isCountryLabelVisible = true;
      }, 300);
    }
  }

  get displayCurrency() {
    if (this.activePaymentMethod) {
      if (this.activePaymentMethod.minPayment.currency && !this.activePaymentMethod.minPayment.convertable) {
        return this.activePaymentMethod.minPayment.currency;
      }
    }

    return this.selectedCountry.currency;
  }

  get minPaymentAmount() {
    let methodMinPayment = this.filteredPaymentMethods[0]?.minPayment.amount;
    if (this.activePaymentMethod) {
      if (this.activePaymentMethod.minPayment.convertable && this.activePaymentMethod.minPayment.currency) {
        methodMinPayment = this.currencyToDisplayCurrency(this.activePaymentMethod.minPayment.amount, this.activePaymentMethod.minPayment.currency);
      } else {
        methodMinPayment = this.activePaymentMethod.minPayment.amount;
      }

    }

    if (this.promoData) {
      methodMinPayment = Math.max(methodMinPayment, this.coinsToDisplayCurrency(this.promoData.amount, true));
    }

    return methodMinPayment;
  }

  get promoData() {
    return this.$store.state.modal.data;
  }

  get isP2PChosen() {
    return this.activePaymentMethod?.isP2P;
  }

  currencyToDisplayCurrency(amount: number, currency: string) {
    const rate = this.rates[currency];
    if (!rate) return null;

    const amountInCoins = this.currencyToCoins(amount, currency);

    return this.coinsToDisplayCurrency(amountInCoins);
  }

  coinsToDisplayCurrency(amount: number, forPromo?: boolean) {
    const rate = this.rates[this.displayCurrency];
    if (!rate) return null;
    const fractionDigits = ['USD', 'EUR'].includes(this.displayCurrency) ? 2 : 0;
    const addition = forPromo && ['UAH', 'KZT'].includes(this.displayCurrency) ? 2 : 0;

    return Number((amount / rate).toFixed(fractionDigits)) + addition;
  }

  currencyToCoins(amount: number, currency: string) {
    const rate = this.rates[currency];
    if (!rate) return null;

    return Math.round(amount * rate);
  }

  get filteredPaymentMethods() {
    return this.paymentMethods.filter(m => {
      const f1 =
          this.paymentFilter != 'all'
              ? m.category === this.paymentFilter || m.category === 'all'
              : true;
      const f2 =
          !this.selectedCountry ||
          !m.countries ||
          m.countries.includes(this.selectedCountry.code);
      const f3 =
          (m.type !== 'PAYSELECTION' || m.type.includes('!')) ||
          this.user?.uuid === '4cc02630-ee87-43a7-9991-7996eca8ec54';

      return f1 && f2 && f3;
    });
  }

  get isButtonDisabled() {
    return (
        !this.amount ||
        !this.activePaymentMethod ||
        !this.conditionsConfirmed ||
        this.amount < this.minPaymentAmount
    );
  }

  get amountInCoins() {
    if (!this.amount) return null;

    const amount = Number(this.amount);
    return this.currencyToCoins(amount, this.displayCurrency);
  }

  get receiveAmount() {
    if (!this.amountInCoins) return null;

    const amount = this.amountInCoins;
    let toBalanceAmount = amount;

    if (
        this.promocode &&
        (!this.promocode.minPayment || this.promocode.minPayment <= amount)
    ) {
      toBalanceAmount += (amount * this.promocode.number) / 100;
    } else {
      if (amount >= 1000) toBalanceAmount = amount * 1.05;
      if (amount >= 3000) toBalanceAmount = amount * 1.1;
      if (amount >= 5000) toBalanceAmount = amount * 1.15;
    }

    return Math.floor(toBalanceAmount);
  }

  get bonusAmount() {
    if (!this.receiveAmount || !this.amountInCoins) return null;

    const bonusInCoins = this.receiveAmount - this.amountInCoins;

    return this.coinsToDisplayCurrency(bonusInCoins);
  }

  get activePaymentMethod() {
    return this.filteredPaymentMethods.filter((item, itemIndex) => {
      return itemIndex == this.chosenPaymentMethodIndex;
    })[0];
  }

  async activatePromocode() {
    try {
      const {inputPromocode} = this;
      this.promocode = null;

      if (!inputPromocode || inputPromocode.length < 3) return;

      const {promocode, error} = await activatePromocode(inputPromocode);
      if (error) {
        if (error.includes('можно использовать')) {
          return this.$notify({
            group: 'notification',
            duration: 5500,
            type: 'warning',
            title: 'Активация промокода',
            text: error,
          });
        }

        return;
      }

      this.promocode = promocode;
    } catch (e) {
      console.error(e);
      this.$notify({
        group: 'notification',
        duration: 5500,
        type: 'error',
        title: 'Активация промокода',
        text: 'Неизвестная ошибка!',
      });
    }
  }

  async refillBalance() {
    try {
      if (!this.conditionsConfirmed) {
        return this.$notify({
          group: 'notification',
          duration: 5500,
          type: 'warning',
          title: 'Пополнение баланса',
          text: 'Для пополнения баланса необходимо принять условия пользовательского соглашения.',
        });
      }

      const amount = this.amountInCoins;
      const paymentMethod =
          this.filteredPaymentMethods[this.chosenPaymentMethodIndex];
      if (this.chosenPaymentMethodIndex === null || !paymentMethod) {
        return this.$notify({
          group: 'notification',
          duration: 5500,
          type: 'warning',
          title: 'Пополнение баланса',
          text: 'Выберите способ оплаты!',
        });
      }

      const {error, href} = await createPayment(paymentMethod.type, amount, this.displayCurrency);

      if (error) {
        return this.$notify({
          group: 'notification',
          duration: 5500,
          type: 'warning',
          title: 'Пополнение баланса',
          text: error,
        });
      }

      if (href) {
        window.location.href = href;
      }
    } catch (e) {
      console.error(e);
      this.$notify({
        group: 'notification',
        duration: 5500,
        type: 'error',
        title: 'Пополнение баланса',
        text: 'Неизвестная ошибка!',
      });
    }
  }

  focusRefillInput() {
    document.getElementById('inputRefill').focus();
  }

  showInputPromo() {
    this.isPromocodeInputVisible = true;
    this.$nextTick(() => {
      document.getElementById('inputPromo').focus();
    });
  }

  @Watch('selectedCountry')
  onCurrencyChanged() {
    this.chosenPaymentMethodIndex = 0;
    this.setDefaultAmount();
  }

  @Watch('minPaymentAmount')
  omMinPayment() {
    this.setDefaultAmount();
  }

  @Watch('paymentFilter')
  onPaymentFilterChanged() {
    this.chosenPaymentMethodIndex = 0;
    this.setDefaultAmount();
  }

  @Watch('amount')
  correctInputWidth() {
    this.payInputWidth = this.amount.toString().length * 10;
  }

  @Watch('amountInCoins')
  onAmountChanged() {
    if (this.amountInCoins > 99999) {
      this.amount = this.coinsToDisplayCurrency(99999);
    }
  }
}
