import Axios from 'axios';

import { MOCK_GET_DROPS } from './mockGetDrops';
import { Drop } from '@/types/Drop';
import { shuffleArray } from '@/helpers';
import axiosRetry from 'axios-retry';

axiosRetry(Axios, {retries: 4, retryDelay: axiosRetry.exponentialDelay});

interface Props {
    type: 'total' | 'top' | 'gift';
}

export default async function getDrops({type}: Props): Promise<Drop[]> {
    if (process.env.NODE_ENV === 'development') {
        return MOCK_GET_DROPS;
    }
    return (await Axios.post<{ drops: Drop[] }>('/api/drop/get', {type})).data
        .drops;
}
