
import { Component, Prop, Vue } from 'vue-property-decorator';
import Button from '@/components/Buttons/Button.vue';
import { TG_LINK, VK_LINK } from '@/constants/socialLinks';
import { legalData } from '@/constants/legalData';

@Component({
  components: {Button},
  computed: {
    legal() {
      return legalData;
    }
  }
})
export default class Footer extends Vue {
  @Prop(String) supportEmail: string;

  socialLink(type: string) {
    return type == 'vk' ? VK_LINK : TG_LINK;
  }
}
