import Axios from 'axios';

import { Promocode } from '@/types/Promocode';
import { MOCK_ACTIVATE_PROMOCODE } from '@/requests/Payment/activatePromocode/mockActivatePromocode';

export default async function activatePromocode(code: string): Promise<{error?: string; promocode?: Promocode}> {
    if (process.env.NODE_ENV === 'development') {
        if (code === 'DEMO') {
            return MOCK_ACTIVATE_PROMOCODE;
        }
        if (code === 'DEMO1') {
            return {promocode: {...MOCK_ACTIVATE_PROMOCODE.promocode, number: 30}};
        }
        return {error: 'Errr'};
    }

    const response = await Axios.post('/api/payment/promocode', {code});
    const {promocode, error} = response.data;

    return {promocode, error};
}
