
import RefillModal from '@/components/Modals/RefillModal.vue';
import ManaModal from '@/components/Modals/ManaModal.vue';
import SimilarWithdrawModal from '@/components/Modals/SimilarWithdrawModal.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({ components: { RefillModal, ManaModal, SimilarWithdrawModal } })
export default class Modals extends Vue {
  isContentReady: boolean = false;

  onBlurReady() {
    setTimeout(() => {
      this.isContentReady = true;
    }, 1);
  }

  onBeforeBlurEnter() {
    document.body.classList.add('with-modal');
  }

  get modalName() {
    return this.$store.state.modal.name;
  }

  closeModal() {
    this.$store.dispatch('modal/toggleModal', { name: null });
    this.isContentReady = false;
    document.body.classList.remove('with-modal');
  }
}
