
import { Component, Vue } from 'vue-property-decorator';

import Button from '@/components/Buttons/Button.vue';
import AuthButton from '@/components/Buttons/AuthButton.vue';
import { MOCK_GET_USER_ACCOUNT } from '@/requests/User/getUser/mockGetUserAccount';
import { TG_LINK, VK_LINK } from '@/constants/socialLinks';

@Component({
  components: { Button, AuthButton },
})
export default class Header extends Vue {
  opacity: boolean = true;
  isUserMenuShown: boolean = false;
  isMobileUserMenuShown: boolean = false;
  usersOnline: number = 0;
  isLogoHovered: boolean = false;

  mounted() {
    this.$socket.client.on('online', (online: number) => {
      this.usersOnline = online;
    });
  }

  get user() {
    return this.$store.getters['user/isAuthorized']
      ? this.$store.state.user
      : null;
  }

  socialLink(type: string) {
    return type == 'vk' ? VK_LINK : TG_LINK;
  }

  openRefill() {
    this.$store.dispatch('modal/toggleModal', { name: 'refill' });
  }

  openMana() {
    this.$store.dispatch('modal/toggleModal', { name: 'mana' });
  }

  get isDebugMode() {
    return process.env.NODE_ENV === 'development';
  }

  toggleUserMenu() {
    this.isUserMenuShown = !this.isUserMenuShown;
  }

  hideUserMenu() {
    this.isUserMenuShown = false;
  }

  toggleMobileUserMenu() {
    this.isMobileUserMenuShown = !this.isMobileUserMenuShown;
  }

  lavkaFromUserMenu() {
    if (this.$route.path != '/promotions') {
      this.$router.push('/promotions');
    }
    this.toggleMobileUserMenu();
  }

  openRefillInDropDown() {
    if (!this.isDebugMode) {
      return this.openRefill();
    }

    this.$store.dispatch('modal/toggleModal', {
      name: 'replaceItem',
      data: {
        replaceItems: [
          {
            image:
              '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUnpx9YXkDfVeDiwsHXVk94KhZTia6sczhp3v7HYylD4OOhkYGbmPm7JrXVk3ld59d1tf3IrbP8hVm7uQc9Pwa0cdfVLVggYVDYrwO5yO7m05LoupiYmnBq6HQk4CnZzhDi1EtLPLQ6gvLLHVjPUbsJQvfH1J973g',
            price: 13,
            color: 'e4ae39',
            type: 'item',
            market_hash_name: 'name',
          },
          {
            image:
              '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUnpx9YXkDfVeDiwsHXVk94KhZTia6sczhp3v7HYylD4OOhkYGbmPm7JrXVk3ld59d1tf3IrbP8hVm7uQc9Pwa0cdfVLVggYVDYrwO5yO7m05LoupiYmnBq6HQk4CnZzhDi1EtLPLQ6gvLLHVjPUbsJQvfH1J973g',
            price: 13,
            color: 'e4ae39',
            type: 'item',
            market_hash_name: 'name',
          },
          {
            image:
              '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUnpx9YXkDfVeDiwsHXVk94KhZTia6sczhp3v7HYylD4OOhkYGbmPm7JrXVk3ld59d1tf3IrbP8hVm7uQc9Pwa0cdfVLVggYVDYrwO5yO7m05LoupiYmnBq6HQk4CnZzhDi1EtLPLQ6gvLLHVjPUbsJQvfH1J973g',
            price: 13,
            color: 'e4ae39',
            type: 'item',
            market_hash_name: 'name',
          },
          {
            image:
              '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUnpx9YXkDfVeDiwsHXVk94KhZTia6sczhp3v7HYylD4OOhkYGbmPm7JrXVk3ld59d1tf3IrbP8hVm7uQc9Pwa0cdfVLVggYVDYrwO5yO7m05LoupiYmnBq6HQk4CnZzhDi1EtLPLQ6gvLLHVjPUbsJQvfH1J973g',
            price: 13,
            color: 'e4ae39',
            type: 'item',
            market_hash_name: 'name',
          },
          {
            image:
              '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUnpx9YXkDfVeDiwsHXVk94KhZTia6sczhp3v7HYylD4OOhkYGbmPm7JrXVk3ld59d1tf3IrbP8hVm7uQc9Pwa0cdfVLVggYVDYrwO5yO7m05LoupiYmnBq6HQk4CnZzhDi1EtLPLQ6gvLLHVjPUbsJQvfH1J973g',
            price: 13,
            color: 'e4ae39',
            type: 'item',
            market_hash_name: 'name',
          },
        ],
        dropItem: {
          id: 1,
          name: '',
          image:
            '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUnpx9YXkDfVeDiwsHXVk94KhZTia6sczhp3v7HYylD4OOhkYGbmPm7JrXVk3ld59d1tf3IrbP8hVm7uQc9Pwa0cdfVLVggYVDYrwO5yO7m05LoupiYmnBq6HQk4CnZzhDi1EtLPLQ6gvLLHVjPUbsJQvfH1J973g',
          price: 200,
          color: 'e4ae39',
          type: 'item',
          market_hash_name: 'Sword',
          drop_id: 2,
          drop_status: 0,
          item: { price: 10 },
        },
      },
    });
  }

  logout() {
    if (!this.isDebugMode) {
      return (window.location.href = '/auth/logout');
    }

    // @ts-ignore
    this.$store.commit('user/update', { ...MOCK_GET_USER_ACCOUNT, uuid: '' });
    this.isMobileUserMenuShown = false;
    this.isUserMenuShown = false;
  }

  isPageActive(name: string) {
    return name !== ''
      ? this.$route.path.indexOf('/' + name) !== -1
      : this.$route.path === '/';
  }

  logoHover() {
    this.isLogoHovered = !this.isLogoHovered;
  }

  toUserProfile() {
    this.$router.push('/account');
  }
}
