import Axios from 'axios';
import { MOCK_WITHDRAW_SIMILAR_ITEM } from '@/requests/Withdraw/withdrawSimilarItem/mockWithdrawSimilarItem';
import { WithdrawItemResponse } from '@/requests/Withdraw/withdrawItem/withdrawItem';

export default async function withdrawSimilarItem(dropId: number, itemToWithdraw: string): Promise<WithdrawItemResponse> {
    if (process.env.NODE_ENV === 'development') {
        return MOCK_WITHDRAW_SIMILAR_ITEM;
    }

    let response;
    response = await Axios.post('/api/drop/withdraw_similar', {
        dropId,
        market_hash_name: itemToWithdraw
    });
    const {error, similarItems} = response.data;

    return {error: error ? error.error || error : undefined, replaceItems: similarItems};
}
