import { render, staticRenderFns } from "./SimilarWithdrawModal.vue?vue&type=template&id=2dde4cd0&scoped=true&"
import script from "./SimilarWithdrawModal.vue?vue&type=script&lang=ts&"
export * from "./SimilarWithdrawModal.vue?vue&type=script&lang=ts&"
import style0 from "./SimilarWithdrawModal.vue?vue&type=style&index=0&id=2dde4cd0&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dde4cd0",
  null
  
)

export default component.exports